import React from 'react'
import maint from '../Maintenance.svg'
import '../css/maint.css'
const Maintenence = () => {
  return (
    <div
      className='leftContainer mb_space'
      // style={{ minHeight: '100vh', height: '100%' }}
    >
      <div className='pt-5 mt-5  Orher_page_main_section'>
        <div
          className='bg_maint'
          style={{ backgroundImage: "url('../images/web_maint.jpg')" }}
        ></div>
        <div className='bg_cont'>सर्वर की वजह से वेबसाइट को कुछ टाइम तक बंद किया गया हैं जल्दी ही वेबसाइट चालू हो जाएगी सभी का पेमेंट बिल्कुल सुरक्षित है</div>
      </div>
    </div>
  )
}

export default Maintenence
