import React, { useContext, useEffect, useState } from "react";
import css from "../css/Mywallet.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import socket from "../Components/socket";
import { AiOutlineHistory, AiOutlinePlus } from "react-icons/ai";
import { BsWallet2 } from "react-icons/bs";
import io from "../Components/socket";
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";


const Mywallet = ({user}) => {
  // const [user, setUser] = useState();

  const access_token = localStorage.getItem("token");
//   const walletUpdate =()=>{
//  io.emit("getprofile", access_token);
//   io.on("setProfile", (data) => {
//     setUser(data);
//   });
//   }

//   useEffect(()=>{
//     walletUpdate()
//   },[]);

// language 
const translations = {
  hi: {
    wallet: 'वॉलेट',
    history: 'इतिहास देखे | ',
    amount: 'पैसे जोड़ें | ',
    withdraw: 'पैसे निकाले  | ',
    referPerson: 'रेफ़रल | ',
  },
  en: {
    wallet: 'My Wallet',
    history: 'Order History',
    amount: 'Add Cash',
    withdraw: 'Withdraw',
    referPerson: 'Referral',
  },
};

const { language } = useContext(LanguageContext);


 // change theme 
 const theme  = localStorage.getItem('theme')

 const styles = {
   backgroundColor: theme === 'light' ? '#e5e5e5' : '#000',
   color: theme === 'light' ? 'black' : '#fff',
 };
 

  return (
    <>
      <div className="leftContainer mb_space" style={{...styles, height:"100vh"}}>
        <div className="pt-5 mt-5  Orher_page_main_section">
          <div className="main_heading_with_btn mb-3">
            <h2 className="profile_headings" style={styles}>{translations[language].wallet}</h2>
            <Link className="profile_wallet" to="/history">
              <AiOutlineHistory className="icons" /> {translations[language].history}
            </Link>
          </div>
          {/* <div style={{ width: "fit-content", marginTop: "10px" }}>
            <a
              href="https://youtu.be/-OocN0l02_s?si=x0enTSJVqedu5NN0"
              target="_blank"
            >
              <span
                className="d-flex align-items-center  btn-md guide_btn"
                style={{
                  right: "0.5rem",
                  backgroundColor: "#003441",
                  color: "white",
                  borderRadius: "4px",
                  padding: "4px 13px",
                  fontSize: "12px",
                }}
              >
                Youtube
                <img
                  className="ml-2"
                  src={"/images/Homepage/youtube.png"}
                  alt=""
                  width="15px"
                />
              </span>
            </a>
          </div> */}

          {/* <div className='main_area'>
            <div
              className='p-4 bg-light'
              style={{ border: '1px solid #e0e0e0', borderRadius: '5px' }}
            >
              <Link
                className={`d-flex align-items-center ${css.profile_wallet} undefined`}
                to='/transaction-history'
              >
                <picture className='ml-4'>
                  <img
                    width='32px'
                    src={
                      process.env.PUBLIC_URL +
                      '/images/Header/order-history.png'
                    }
                    alt=''
                  />
                </picture>
                <div className={`ml-5 ${css.mytext} text-muted `}>
                  Order History
                </div>
              </Link>
            </div>
          </div> */}

          <div className="mt-2">
            <div className="wallet_page_cards">
              <div
                className="profile_headings"
                style={{ display: "flex", alignItems: "center",}}
              >
              <img src="/deposit-case.png" /> 
              
              <div className="ml-2">
              <span class="d-block mb-2"> Deposit Cash{" "}</span>
                <span
                  className=" ml-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                {/* <img src="./nkcoin.png"  style={{width:"22px", marginRight:"5px"}}/> */}
                  {user && user.Wallet_balance - user.withdrawAmount}
                  
                </span>
                </div>
                <div className="ml-auto">
                <Link className="block_end_text" to="/addcase">
                <button className="samll_btn">
                  <AiOutlinePlus className="icons" /> {translations[language].amount}
                </button>
              </Link>
                </div>
              </div>
              <p className="">
                Can be used to play Tournaments &amp; Battles. Cannot be
                withdrawn to Paytm or Bank.
              </p>
             
            </div>
            <div className="wallet_page_cards">
              <div
                className="profile_headings"
                style={{ display: "flex", alignItems: "center", }}
              >
                 <img className=" bg-danger" src="/winning-cash.png" /> 
                 <div class="ml-2">
                 <span className="d-block mb-2">
                 Winning Cash
                 </span>
                <span
                  className=" ml-2"
                  style={{ display: "flex", alignItems: "center" }}
                >
                                

                  {user && user.withdrawAmount}
                </span>
                 </div>
                 <div className="ml-auto">
                 <Link className="block_end_text" to="/Withdrawopt">
                <button className="samll_btn">
                  {" "}
                  <BsWallet2 className="icons" /> {translations[language].withdraw}
                </button>
              </Link>
                 </div>
                 
              </div>

              <p>
                Can be withdrawn to Paytm or Bank. Can be used to play
                Tournaments &amp; Battles.
              </p>
              
            </div>
          </div>
        </div>
        <div className="rightContainer">
          <div>
            <Rightcontainer />
          </div>
        </div>
      </div>
    </>
  );
};
export default Mywallet;
