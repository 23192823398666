import React, { useState, useEffect } from 'react'
import css from '../css/gamehis.module.css'
import ReactPaginate from 'react-paginate'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import { BsChatDots } from 'react-icons/bs'
// import Header from "../Components/Header";
import Rightcontainer from '../Components/Rightcontainer'
import axios from 'axios'
import socket from '../Components/socket'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { toast } from 'react-toastify'
import { toggleWidget } from 'react-chat-widget'

const Gamehistory = ({ TicketList, Conversation }) => {
  // console.log(Conversation)
  // BASE URL
 
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
}
// +++++++++++++++++++++++++++++ 
  const [user, setUser] = useState()
  let limit = 50
  const [pageNumber, setPageNumber] = useState(0)
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  let history = useHistory()

  const role = async () => {
    socket.emit('getprofile')

    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    await axios
      .get(baseUrl+`me`, { headers })
      .then(res => {
        setUser(res.data)

        //Allgames(res.data._id)
        Allgames(res.data._id, pageNumber, limit)
        // window.location.reload()
      })
  }

  const handlePageClick = async data => {
    let currentPage = data.selected + 1
    setPageNumber(currentPage)
   
  }
  const [game, setGame] = useState([])
  // console.log(game)

  const Allgames = async id => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    setIsLoading(true)
    await axios
      .get(
        baseUrl+`game/history/user/${id}?page=${pageNumber}&_limit=${limit}`,
        { headers }
      )
      .then(res => {
        let gameHis = []
        res.data.data.forEach(ele => {
          if (
            ele.Status == 'completed' ||
            ele.Status == 'cancelled' ||
            ele.Status == 'conflict'
          ) {
            gameHis.push(ele)
          }
        })
        setGame(gameHis)
        setNumberOfPages(res.data.totalPages)
        setIsLoading(false)
      })
  }
  const dateFormat = e => {
    const date = new Date(e)
    const newDate = date.toLocaleString('default', {
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      hour12: true,
      minute: 'numeric'
    })
    return newDate
  }

  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [pageNumber, limit])


// All history Api 







  // UserList

  const UserList = id => {
    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/x-www-form-urlencoded')
    myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`)

    var urlencoded = new URLSearchParams()
    // urlencoded.append("agent_id", "63b5340bc5d2b82e4652b216");
    urlencoded.append('supportType', 'game')
    urlencoded.append('issue', id)

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    }

    fetch(
      baseUrl+'chat/genrate-ticket',
      requestOptions
    ).then(response => response.json())
    // toggleWidget()
    TicketList()
    Conversation()

    toast
      .success('Your Ticket Generate Sucessfully')
      .then(result => console.log(result))
    toast
      .error('Something Went Wrong')
      .catch(error => console.log('error', error))
  }


    // change theme 
    const theme  = localStorage.getItem('theme')
  
 const styles = {
  backgroundColor: theme === 'light' ? '#fff' : 'black',
  color: theme === 'light' ? 'black' : '#fff',
};

  return (
    <div>
      <div className='leftContainer ' style={{...styles, paddingTop:"10px"}}>
        <div className='pt-2 mt-0  Orher_page_main_section' style={{...styles, paddingBottom:"10vh !important"}}>
          {/* <h2 className='profile_headings'>Game History</h2> */}

          {/* pagination */}

          {/* <div className=''>
            <div className=''>
              <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={numberOfPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={'pagination justify-content-center'}
                pageClassName={'page-item'}
                pageLinkClassName={'page-link'}
                previousClassName={'page-item'}
                previousLinkClassName={'page-link'}
                nextClassName={'page-item'}
                nextLinkClassName={'page-link'}
                breakClassName={'page-item'}
                breakLinkClassName={'page-link'}
                activeClassName={'active'}
              />
            </div>
          </div> */}
          {/* game card   */}

          {isLoading ? (
            <div className='text-center'>
              <div className='loaderReturn' style={{ zIndex: '99' }}>
                <img
                  src={'/images/LandingPage_img/loader1.gif'}
                  style={{ width: '100%' }}
                />
              </div>
              {/* Add any loading spinner or animation here */}
            </div>
          ) : game && game.length == 0 ? (
            <div className='text-center not_found_box_main'>
              <img
                src='./images/not-found.gif'
                alt='no data'
                // width={300}
                // height={300}
                // className='img-fluid'
                // style={{ marginTop: '25%' }}
              />
              <div className='mt-2'>
                <h3 className='font-weight-bold'>No Game History</h3>
                <p>You have no game history yet.</p>
              </div>
            </div>
          ) : (
            game &&
            game.map(card => (
              <>
                <div className='other_pages_card_main'>
                  <div>
                    <p>
                      Status :
                      <span className='ml-2 text-danger'>
                        <b>
                          {card.Status === 'completed'
                            ? card.Winner && card.Winner._id === user._id
                              ? 'Win Against'
                              : 'Lost Against'
                            : card.Status}
                        </b>
                      </span>
                    </p>
                    <p>
                      Player Name :
                      <span className='ml-2 ' style={{color:'blue'}}>
                        <b>
                          {card.Accepetd_By && card.Accepetd_By._id === user._id
                            ? card.Created_by.Name
                            : card.Accepetd_By && card.Accepetd_By.Name}
                        </b>
                      </span>
                    </p>
                    <p className=' transaction_msg'>
                      Battle ID :<span>{card._id}</span>
                    </p>
                  </div>
                  <div className='transaction_right_side_main'>
                    <div className=' gaming_chat_icon_main'>
                      <button
                        className='gaming_chat_icon'
                        onClick={() => UserList(card._id)}
                      >
                        {/* <SupportAgentIcon /> */}
                        <BsChatDots />
                      </button>
                    </div>
                    <div style={{color:"blue",fontSize: '0.8em', whiteSpace: 'nowrap' }}>
                      closing balance :
                      {card.Winner === user._id
                        ? card.Winner_closingbalance
                        : card.Loser_closingbalance}
                    </div>
                    
                    <div className='card_date gaming_chat_icon_main'>
                      <p className='mr-1'>
                        {dateFormat(card.createdAt).split(',')[0]}
                      </p>
                      <p>{dateFormat(card.createdAt).split(',')[1]}</p>
                    </div>
                  </div>
                </div>
                {/* map the cardData */}
                {/* <div
                  className={`w-100 mt-3 py-3 d-flex align-items-center ${css.list_item}`}
                >
                  <div className={`${css.center_xy} ${css.list_date} mx-2`}>
                    <div>{dateFormat(card.createdAt).split(',')[0]}</div>
                    <small>{dateFormat(card.createdAt).split(',')[1]}</small>
                  </div>
                  <div className={`${css.list_divider_y}`} />
                  <div className={`mx-3 d-flex ${css.list_body}`}>
                    <div className='d-flex align-items-center'>
                      <picture className='mr-2'>
                        <img
                          height='32px'
                          width='32px'
                          src={
                            process.env.PUBLIC_URL +
                            '/images/LandingPage_img/ludo.jpeg'
                          }
                          alt=''
                          style={{ borderRadius: '5px' }}
                        />
                      </picture>
                    </div>

                    <div className='d-flex flex-column font-8'>
                      <div>
                        {card.Status === 'completed'
                          ? card.Winner && card.Winner._id === user._id
                            ? 'Win Against'
                            : 'Lost Against'
                          : card.Status}
                        <b>
                          {card.Accepetd_By && card.Accepetd_By._id === user._id
                            ? card.Created_by.Name
                            : card.Accepetd_By && card.Accepetd_By.Name}
                        </b>
                        .
                      </div>
                      <div className={`${css.games_section_headline}`}>
                        battle _id: {card._id}
                      </div>
                    </div>
                  </div>
                  <div className='d-flex flex-column font-4 '>
                    <Button
                      variant=''
                      onClick={() => UserList(card._id)}
                      style={{ textAlign: 'right' }}
                    >
                      <SupportAgentIcon />
                    </Button>
                  </div>
                  {card.Status === 'completed' && (
                    <div
                      style={{ marginLeft: 'auto' }}
                      className='right-0 d-flex align-items-end pr-3 flex-column'
                    >
                      <div className='d-flex float-right font-8'>
                        <div className='text-danger'>{card.losestatus}</div>
                        <div className='text-success'>{card.winstatus}</div>
                        <picture className='ml-1 mb-1'>
                          <img
                            height='21px'
                            width='21px'
                            src={
                              process.env.PUBLIC_URL +
                              '/images/LandingPage_img/global-rupeeIcon.png'
                            }
                            alt=''
                          />
                        </picture>
                        <span className='pl-1'>
                          {card.Status === 'completed'
                            ? card.Winner._id === user._id
                              ? `(+) ${card.winnAmount}`
                              : `(-) ${card.Game_Ammount}`
                            : ''}
                        </span>
                      </div>
                      <div
                        className={`${css.games_section_headline}`}
                        style={{ fontSize: '0.6em', whiteSpace: 'nowrap' }}
                      >
                        closing balance :
                        {card.Winner === user._id
                          ? card.Winner_closingbalance
                          : card.Loser_closingbalance}
                      </div>
                    </div>
                  )}
                </div> */}
              </>
            ))
          )}
        </div>
      </div>

      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </div>
  )
}

export default Gamehistory
